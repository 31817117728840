body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

/* creo site identity */

.bg-site-primary {
  @apply bg-slate-700;
}

.bg-site-primary-hover {
  @apply hover:bg-slate-700;
}

.text-color-site-primary {
  @apply text-slate-700;
}

.text-hover-color-site-primary {
  @apply hover:text-slate-700;
}

.border-color-site-primary {
  @apply border-slate-700;
}

.border-hover-color-site-primary {
  @apply hover:border-slate-700;
}
