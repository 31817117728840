body {
  font-family: "Amatic SC", serif;
  font-size: 18px;
  color: #444242;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8bc43f;
}

/* creo site identity */

.bg-site-primary {
  @apply bg-[#8bc43f];
}

.bg-site-primary-hover {
  @apply hover:bg-[#8bc43f];
}

.text-color-site-primary {
  @apply text-[#8bc43f];
}

.text-hover-color-site-primary {
  @apply hover:text-[#8bc43f];
}

.border-color-site-primary {
  @apply border-[#8bc43f];
}

.border-hover-color-site-primary {
  @apply hover:border-[#8bc43f];
}

/* common components */

.common-footer {
  @apply bg-[#121212] text-white font-["Amatic_SC",cursive] tracking-widest text-lg font-bold;
}

.common-header-dropdown-button-label {
  @apply text-base font-bold text-[#444242] tracking-wide;
}

.common-header-contact-dropdown {
  @apply text-lg font-bold;
}

.common-header-contact-dropdown-data {
  @apply text-lg font-bold;
}

.common-header-brands-dropdown-label {
  @apply text-xl font-bold;
}

/* site specific */

.heading {
  @apply text-[#444242] text-4xl sm:text-5xl font-["Patua_One",cursive] tracking-wider uppercase;
}

.sub-heading {
  @apply text-[#444242] text-2xl font-bold font-["Amatic_SC",cursive] tracking-wide;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}